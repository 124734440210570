import {
  Div,
  Ul,
  Li,
  UlSocialNet,
  LiSocialNet,
  ImageSocialLinkContainer,
  ImageSocialLink,
  ColLink,
  Span,
  DivCopyright,
  ColLinkText,
} from "./styles";
import { compareValues } from "@helpers/arrayFuntions";
import { Col, Row } from "react-bootstrap";
import { useAuth } from "@contexts/AuthContext";
import { IMAGE_PATH } from "@helpers/config";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";

type FooterProps = {
  show: boolean;
  orgId?: number;
  langCode: string;
};

export const Footer: React.FC<FooterProps> = ({ show }) => {
  const { isLogged } = useAuth();
  const { footer } = useLayoutStore(
    (state) => ({
      footer: state.footer,
    }),
    shallow
  );

  const redirectTo = (url: string) => {
    // window.location.replace(url);
    window.open(url, "_blank");
  };
  const Links = ({ links }: { links?: FooterLinkType[] }) => {
    const linksOrder = links?.sort(compareValues("orderNo", "desc"));
    let ncol = 1;

    if (linksOrder && linksOrder?.length > 3) {
      ncol = linksOrder?.length / 3;
      ncol = Math.ceil(ncol);
      if (ncol > 5) {
        ncol = 5;
      }
    }

    const linkNoAuth = linksOrder?.filter((e) => {
      return !e.authRequired;
    });

    return (
      <>
        {isLogged ? (
          <Ul theme={{ ncol }} className="custom-pading-footer">
            {linksOrder?.map((l, index) => {
              return (
                <Li key={`footer_li_${index}`}>
                  <Span
                    onClick={() => {
                      redirectTo(l.url);
                    }}
                  >
                    {`${l.text}`}
                  </Span>
                </Li>
              );
            })}
          </Ul>
        ) : (
          <Ul theme={{ ncol }} style={{ marginTop: "1.5rem" }}>
            {linkNoAuth?.map((aut, index) => {
              return (
                <Li key={`footer_li_${index}`}>
                  <Span
                    onClick={() => {
                      redirectTo(aut.url);
                    }}
                  >
                    {`${aut.text}`}
                  </Span>
                </Li>
              );
            })}
          </Ul>
        )}
      </>
    );
  };
  const SocialLinks = ({ socialNet }: { socialNet?: SocialLinkType[] }) => {
    return (
      <UlSocialNet>
        {socialNet?.map((sn, index) => {
          return (
            <LiSocialNet
              key={`footer_sn_${index}`}
              onClick={() => {
                redirectTo(sn.url);
              }}
            >
              <ImageSocialLinkContainer>
                <ImageSocialLink
                  className="social-media-image"
                  src={`${IMAGE_PATH}${sn.img}`}
                  alt={"logo"}
                  width={30}
                  height={30}
                />
              </ImageSocialLinkContainer>
            </LiSocialNet>
          );
        })}
      </UlSocialNet>
    );
  };
  const getText = () => {
    try {
      return footer?.copyright;
    } catch (error) {
      console.log("error", error);

      return "";
    }
  };

  if (!show) {
    return (
      <footer>
        <div></div>
      </footer>
    );
  }

  return (
    <footer className="footer footer_text">
      <Div className="footer footer_text">
        <Row>
          <ColLink className={"footerlayout"} xs={12} md={12} lg={8}>
            <Links key={`footer_link_${1}`} links={footer?.links} />
          </ColLink>
          <Col xs={12} md={12} lg={4}>
            <DivCopyright className="footer footer_text" fluid>
              <Row>
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 12, order: 2 }}
                  lg={{ span: 12, order: 1 }}
                >
                  <SocialLinks
                    key={`footer_socialLinks`}
                    socialNet={footer?.socialLinks}
                  />
                </Col>
                <ColLinkText
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 12, order: 1 }}
                  lg={{ span: 12, order: 2 }}
                >
                  {getText()}
                </ColLinkText>
              </Row>
            </DivCopyright>
          </Col>
        </Row>
      </Div>
    </footer>
  );
};
